

























import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import { SignatureRequestedViewModel }
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/change-bank-account/status/singature-requested-view-model';

@Component({ name: 'SignatureRequested' })
export default class SignatureRequested extends Vue {
  @PropSync('email', { type: String })
  synced_email!: string;

  signature_requested_model = Vue.observable(
    new SignatureRequestedViewModel(),
  );
}
